<template>
  <modal-card title="Verify phone client" cancel-text="Close">
    <div v-if="!didSearch" class="has-text-centered">
      <pin-input
        v-model="supportPin"
        :auto-focus-next="true"
        @keyup.enter.native="doSearch"
        @valid="hasValidPin = $event"
      />
    </div>

    <div v-if="!$_.isEmpty(usersArray)" class="">
      <vessel
        v-for="user in usersArray"
        :key="user._id"
        type="router-link"
        :to="{ path: `/admin/users/${user._id}` }"
        @click.native="onClick(user._id)"
      >
        <avatar
          slot="media"
          :name="user.name || user.email"
          :avatar="user.avatarUrl"
          :size="45"
        />
        <p slot="topLeft">
          <strong>{{ user.name || user.email }}</strong>
        </p>
        <p slot="bottomLeft">
          <span class="has-text-grey">{{ user.email }}</span>
        </p>
        <b-icon slot="action" icon="chevron-right" size="is-small" />
      </vessel>
    </div>
    <no-results
      v-if="didSearch && !loading && $_.isEmpty(usersArray)"
      icon="ban"
      title="Client verification failed"
      :message="`The pin '${supportPin}' returned no results.`"
      :cta="{ label: 'Try again', autofocus: true }"
      @click="reset"
    />

    <div slot="footer">
      <button
        v-if="loading || !didSearch"
        class="button is-primary"
        :class="{ 'is-loading': loading }"
        :disabled="!hasValidPin || loading"
        @click="doSearch"
      >
        Verify
      </button>
      <button v-else-if="didSearch" class="button is-text" @click="reset">
        Reset
      </button>
    </div>
  </modal-card>
</template>

<script>
import { collection, getDocs, query, where } from "@firebase/firestore";
export default {
  name: "VerifyClientModal",
  data() {
    return {
      supportPin: "",
      users: {},
      loading: false,
      didSearch: false,
      hasValidPin: false
    };
  },
  computed: {
    agentId() {
      return this.$store.getters["auth/userId"]();
    },
    usersArray() {
      return this.$_.toArray(this.users);
    }
  },
  methods: {
    doSearch() {
      if (!this.hasValidPin) return;
      this.didSearch = true;
      this.loading = true;
      this.users = {};
      getDocs(
        query(
          collection(this.$firestore, "users"),
          where("supportPin", "==", this.supportPin)
        )
      )
        .then(snap => {
          snap.forEach(doc => {
            this.$set(
              this.users,
              doc.id,
              this.$_.merge({}, { _id: doc.id }, doc.data())
            );
          });
        })
        .catch(() => {
          this.$toast.open({
            message: `Error verifying PIN`,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.supportPin = "";
      this.didSearch = false;
      this.hasValidPin = false;
      this.users = {};
    },
    onClick(userId) {
      this.$store.dispatch("log/add", {
        event: "passed-phone-verification",
        metadata: {
          userId,
          agentId: this.agentId
        }
      });
      this.$emit("close");
    }
  }
};
</script>
